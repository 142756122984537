import React, { useState } from 'react';
import Image from 'next/image';
import { Form, Formik } from 'formik';
import jsonp from 'jsonp';
import qs from 'query-string';

import { FooterJoinSchema } from './schema';
import styles from './JoinForm.module.scss';

import apiUrls from '@constants/api-urls';
import FormikField from '@components/shared/FormikField';
import Button from '@components/shared/Button/Button';
import FormError from '@components/shared/Form/Error/FormError';
import useCohesion from '@hooks/useCohesion';
import { emailSubscribed } from '@utils/sendEvent';

const JoinForm = () => {
  const [formWasSubmitted, setFormSubmitted] = useState(false);
  const [isCohesionLoading] = useCohesion();

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    const queryString = qs.stringify({ EMAIL: values.email });
    jsonp(`${apiUrls.mailchimpSubscriptionFormURL}&${queryString}`, { param: 'c' }, (err, data) => {
      if (err || data.result === 'error') {
        actions.setErrors({ non_field_errors: data?.msg || 'Error' });
      } else {
        setFormSubmitted(true);

        // Cohesion tracking
        if (!isCohesionLoading) {
          emailSubscribed({
            signupSource: 'footer',
            email: values.email,
            createdAt: new Date().toISOString()
          });
        }

        actions.resetForm();
      }
      actions.setSubmitting(false);
    });
  };

  return (
    <section className={styles['footer-join']}>
      <h2 className="footer-join__title">Join our travel revolution</h2>
      <h4 className="footer-join__subtitle">
        Sign up to stay in the know - hot new travel spots, <br />
        how we strive to make the world a better place, and all sorts of surprises.
      </h4>
      {!formWasSubmitted && (
        <Formik initialValues={{ email: '' }} validationSchema={FooterJoinSchema} onSubmit={handleSubmit}>
          {({ isSubmitting, errors }) => (
            <Form className="footer-join__form">
              <FormikField className="footer-join__input" label="Your email" type="email" name="email" />
              <FormError
                centered
                error={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: errors.non_field_errors
                    }}
                  />
                }
              />
              <Button className="footer-join__button" type={isSubmitting ? 'button' : 'submit'}>
                {isSubmitting ? 'Please wait...' : 'Sign Up'}
              </Button>
            </Form>
          )}
        </Formik>
      )}
      {formWasSubmitted && (
        <div className="success-message">
          <div className="subscribe-feedback">
            <Image
              src="/webflow/images/check-bullet-blue.svg"
              alt=""
              className="subscribe-check"
              width={33}
              height={33}
            />
            <h5 className="h5-center no-margin">Boom! Welcome to the party.</h5>
          </div>
        </div>
      )}
    </section>
  );
};

export default JoinForm;
