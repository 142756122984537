import React from 'react';
import Image from 'next/image';

import { elsewhere } from '@styles/theme';

const InboxIcon = ({ colorful }: { colorful?: boolean }) => (
  <Image src="/icons/icon-16-inbox-black.svg" width="20px" height="20px" color="gray-8" />
);

export default InboxIcon;
