/* eslint-disable @next/next/no-img-element */
import React from 'react';

import styles from './FooterSocials.module.scss';

import socialNetworks from '@constants/social-networks';

const socialsList = [
  {
    icon: '/img/icon-24-insta.svg',
    href: socialNetworks.instagram,
    name: 'Instagram'
  },
  {
    icon: '/img/icon-24-tiktok.svg',
    href: socialNetworks.tiktok,
    name: 'TikTok'
  },
  {
    icon: '/img/icon-24-facebook.svg',
    href: socialNetworks.fb,
    name: 'Facebook'
  },
  {
    icon: '/img/icon-24-linkedin.svg',
    href: socialNetworks.linkedin,
    name: 'Linkedin'
  },

  {
    icon: '/img/icon-24-youtube.svg',
    href: socialNetworks.youtube,
    name: 'Youtube'
  }
];

const FooterSocials = () => (
  <div className={styles['footer-socials']}>
    {socialsList.map(({ icon, name, href }) => (
      <a key={name} href={href} className="footer-socials__item" rel="noreferrer nopener" target="_blank">
        <img className="footer-socials__img" src={icon} alt={name} />
      </a>
    ))}
  </div>
);

export default FooterSocials;
