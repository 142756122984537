import React from 'react';
import { Box, Button, Link, ListItemText, Menu, MenuItem } from '@mui/material';

import { elsewhere } from '@styles/theme';
import routes from '@constants/routes';

const MenuList = ({
  userStore,
  id,
  open,
  onClose,
  anchorEl,
  items,
  isDesktop,
  anchorOrigin,
  transformOrigin
}: {
  userStore?: any;
  id: string;
  open: boolean;
  onClose: () => void;
  anchorEl: any;
  items: any[];
  isDesktop: boolean;
  anchorOrigin?: any;
  transformOrigin?: any;
}) => (
  <Menu
    id={id}
    open={open}
    onClose={onClose}
    anchorEl={anchorEl}
    marginThreshold={0}
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin}
    disableScrollLock
    PaperProps={
      !isDesktop && {
        style: {
          width: '60%',
          maxWidth: '260px',
          left: 0,
          right: 0,
          top: 10
        }
      }
    }
  >
    {items.map((item, index) =>
      item.title !== 'Craft your trip' ? (
        <Link underline="none" key={index} href={item.href} onClick={item.onClick && (() => item.onClick(userStore))}>
          <MenuItem key={index}>
            <ListItemText
              sx={{
                fontWeight: elsewhere.fontWeight.book,
                color: elsewhere.color['gray-7']
              }}
              primary={item.title}
            />
          </MenuItem>
        </Link>
      ) : (
        <Box
          sx={{
            width: '100%',
            pl: '12px',
            pb: '4px',
            pt: '12px',
            pr: '12px',
            mt: '4px',
            borderTop: '1px solid #EBE8F1'
          }}
        >
          <Button
            onClick={item.onClick && (() => item.onClick(userStore))}
            key={index}
            variant="contained"
            size="medium"
            sx={{ width: '100%' }}
            href={routes.requestForm.index}
          >
            {item.title}
          </Button>
        </Box>
      )
    )}
  </Menu>
);

export default MenuList;
