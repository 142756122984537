import React from 'react';
import { Box, Grid, IconButton, Link, Stack, useTheme } from '@mui/material';

import Icon from '@components/shared/Icon/Icon';
import { elsewhere } from '@styles/theme';
import MenuList from '@components/shared/MenuList';
import { elementClicked } from '@utils/sendEvent';

export interface MenuItem {
  title: string;
  href: string;
  hideOnCustomerArea: boolean;
}

const HamburgerMenu = ({
  items,
  customerArea,
  colorful
}: {
  items: MenuItem[];
  customerArea: boolean;
  colorful: boolean;
}) => {
  const theme = useTheme();
  const [isDesktop, setDesktop] = React.useState(false);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const media = window.matchMedia(theme.breakpoints.up('md').replace('@media ', ''));

      if (media.matches) {
        setDesktop(true);
      }
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <Grid item xs textAlign="left">
      <Box
        display={isDesktop ? 'flex' : 'none'}
        sx={{
          flexDirection: 'row',
          textAlign: 'center',
          alignItems: 'center',
          gap: 4
        }}
      >
        {items.map((item, index) =>
          customerArea && item.hideOnCustomerArea ? null : (
            <Link
              key={item.title}
              variant="body1"
              underline="hover"
              color={colorful ? elsewhere.color['gray-8'] : 'white'}
              fontWeight={elsewhere.fontWeight.medium}
              href={item.href}
              onClick={() => {
                elementClicked({
                  actionOutcome: 'INTERNALLINK',
                  outboundUrl: 'null',
                  webElement: {
                    elementType: 'link',
                    location: 'nav bar',
                    name: item.title,
                    position: 'nav bar',
                    text: item.title
                  }
                });
              }}
            >
              {item.title}
            </Link>
          )
        )}
      </Box>
      <Stack display={isDesktop ? 'none' : 'flex'} alignItems="flex-start">
        <IconButton
          sx={{
            padding: 0,
            backgroundColor: 'transparent',
            ':hover': {
              backgroundColor: 'transparent'
            },
            color: colorful ? elsewhere.color['gray-8'] : 'white'
          }}
          aria-label="menu"
          aria-controls={open ? 'links-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={(event: any) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          {open ? <Icon size={38} icon="icon_close_hamburger" /> : <Icon size={38} icon="icon_open_hamburger" />}
        </IconButton>
        <MenuList
          id="links-menu"
          open={open}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          items={items}
          isDesktop={isDesktop}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        />
      </Stack>
    </Grid>
  );
};

export default HamburgerMenu;
