import React from 'react';
import { Badge, Button, IconButton, useMediaQuery, useTheme } from '@mui/material';

import styles from './inboxButton.module.scss';

import routes from '@constants/routes';
import { elsewhere } from '@styles/theme';
import InboxIcon from '@components/Inbox/InboxIcon';

const InboxButton = ({
  notificationCount = 0,
  chatId,
  colorful
}: {
  notificationCount: number;
  chatId?: number;
  colorful: boolean;
}) => {
  const theme = useTheme();
  // TODO: replace all instances of useMediaQuery with useState and useEffect
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  const chatUrl = chatId ? routes.inbox.chat(chatId) : routes.inbox.index;

  return (
    <Badge className={styles['header-badge']} badgeContent={notificationCount} color="error">
      {isDesktop ? (
        <Button
          size="medium"
          href={chatUrl}
          startIcon={<InboxIcon />}
          aria-label="inbox"
          sx={{
            backgroundColor: '#fff',
            fontSize: '14px',
            minWidth: '92px',
            mr: '8px',
            px: '16px',
            color: 'gray-8',
            textDecoration: 'none',
            ':hover': {
              textDecoration: 'underline',
              backgroundColor: elsewhere.color['blue-super-light']
            },
            img: {
              width: '18px!important',
              height: '18px!important'
            }
          }}
        >
          Inbox
        </Button>
      ) : (
        <IconButton
          sx={{
            backgroundColor: elsewhere.color['blue-super-light'],
            width: '32px',
            height: '32px',
            padding: 0,
            marginRight: '6px'
          }}
          aria-label="inbox"
          href={chatUrl}
        >
          <InboxIcon colorful={colorful} />
        </IconButton>
      )}
    </Badge>
  );
};

export default InboxButton;
