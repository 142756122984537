const socialNetworks = {
  fb: 'https://www.facebook.com/elsewhere.io',
  instagram: 'https://www.instagram.com/elsewhere.io/',
  twitter: 'https://twitter.com/elsewhereio',
  youtube: 'https://www.youtube.com/channel/UCHk8E8_TZqeu8A0dhLSVAAw',
  linkedin: 'https://www.linkedin.com/company/elsewhereio/',
  tiktok: 'https://www.tiktok.com/@elsewhere.io'
};

export default socialNetworks;
