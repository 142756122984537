import React from 'react';
import Image from 'next/image';

import styles from './Partners.module.scss';

const partnersList = [
  {
    path: '/img/partners/climate-neutral.png',
    alt: 'Climate Neutral',
    size: [50, 39]
  },
  {
    path: '/img/partners/eden-png.png',
    alt: 'Eden',
    size: [105, 52]
  },
  {
    path: '/img/partners/plastic-bank.png',
    alt: 'Plastic bank',
    size: [90, 58]
  },
  {
    path: '/img/partners/ally.png',
    alt: 'Ally',
    size: [100, 38]
  },
  {
    path: '/img/partners/wanderwell.png',
    alt: 'Wanderwell',
    size: [120, 47]
  }
];

const FooterPartners = () => (
  <section className={styles['footer-partners']}>
    {partnersList.map(({ path, alt, size }) => (
      <div className="footer-partners__item" key={path}>
        <Image src={path} alt={alt} width={size[0]} height={size[1]} />
      </div>
    ))}
  </section>
);

export default FooterPartners;
