import React from 'react';

import styles from './QASection.module.scss';

import { brandQAUrl } from '@constants/constants';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';

const QaSection = () => (
  <section className={styles['footer-qa']}>
    <h3 className="footer-qa__title">Questions about Elsewhere?</h3>
    <Button
      className="footer-qa__btn btn--primary w-inline-block"
      variant="outline"
      href={brandQAUrl}
      target="_blank"
      rel="noreferrer noopener"
    >
      Check out our brand Q&A <Icon icon="arrow-right" size={18} />
    </Button>
  </section>
);

export default QaSection;
