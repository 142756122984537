import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import routes from '@constants/routes';

const FooterDestinations = ({ regionsStore }) => (
  <div className="footer__destinations">
    <div className="footer__category">Destinations</div>
    <div className="footer__destinations-list">
      {regionsStore.data.map((region) => (
        <div key={region.name} className="footer__group">
          <div className="footer__group-title">{region.name}</div>
          <ul className="footer__list" role="list">
            {region.destinations.map((destination) => (
              <li key={destination.id}>
                <Link href={routes.destinations.details(destination.slug)}>
                  <a className="footer__nav">{destination.name}</a>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  </div>
);

FooterDestinations.propTypes = {
  regionsStore: PropTypes.object
};

export default inject('regionsStore')(observer(FooterDestinations));
