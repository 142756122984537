import { Avatar, Button, IconButton, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import Icon from '@components/shared/Icon/Icon';
import { elsewhere } from '@styles/theme';
import MenuList from '@components/shared/MenuList';

const AvatarDropdownMenu = ({ userStore, items, colorful }: { userStore: any; items: any[]; colorful?: boolean }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      {isDesktop ? (
        <Button
          size="medium"
          aria-controls={open ? 'user-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={(event: any) => {
            setAnchorEl(event.currentTarget);
          }}
          aria-label="user menu"
          sx={{
            color: colorful ? elsewhere.color['gray-8'] : 'white',
            backgroundColor: 'transparent',
            mx: '0',
            pr: '4px',
            pl: '0',
            minWidth: 'auto',
            ':hover': {
              backgroundColor: colorful ? elsewhere.color['blue-super-light'] : '#1c1a2126'
            }
          }}
        >
          <Avatar
            src={userStore?.data?.avatar?.file}
            sx={{
              width: 36,
              height: 36,
              border: colorful ? 'none' : '1px solid #fff'
            }}
          />
          <Icon
            size={24}
            icon={colorful ? 'icon_24_menu' : 'icon_24_menu_white'}
            style={{ position: 'relative', left: '-2px' }}
          />
        </Button>
      ) : (
        <IconButton
          sx={{
            padding: '0',
            backgroundColor: colorful ? elsewhere.color['blue-super-light'] : '#1c1a2126',
            ':hover': {
              backgroundColor: colorful ? elsewhere.color['blue-super-light'] : '#1c1a2140'
            }
          }}
          aria-label="user menu"
          aria-controls={open ? 'user-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={(event: any) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <Avatar src={userStore?.data?.avatar?.file} sx={{ width: 32, height: 32 }} />
        </IconButton>
      )}

      <MenuList
        userStore={userStore}
        id="user-menu"
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        items={items}
        isDesktop={isDesktop}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      />
    </>
  );
};

export default AvatarDropdownMenu;
